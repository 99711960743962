import { Companion } from 'components/video-ads';
import { useIsBreakpointWidth } from 'lib/hooks/useIsBreakpointWidth';

export const TalkAdBilllBoardBTF = () => {
  const isMobile = useIsBreakpointWidth({ size: 'sm', breakPointType: 'ads' });
  const responsiveAd = () => {
    if (!isMobile) {
      return (
        <Companion
          type="LargeRectangle"
          path={process.env.NEXT_PUBLIC_AD_PATH_TALKS || ''}
          identifier="talkpage-billboard-btf"
          className="mb-10"
        />
      );
    }
    if (isMobile) {
      return (
        <Companion
          type="SmallRectangleThin"
          identifier="talkpage-billboard-btf"
          path={process.env.NEXT_PUBLIC_AD_PATH_TALKS || ''}
          className="my-6"
          showTrim={false}
        />
      );
    }
    return <></>;
  };
  return responsiveAd();
};

export const TalkAdSideBarATF = () => {
  const isMobile = useIsBreakpointWidth({ size: 'sm', breakPointType: 'ads' });
  const isTalkTablet = useIsBreakpointWidth({
    size: 'lg',
    breakPointType: 'ads'
  });
  const isDesktop = !isMobile || !isTalkTablet;
  // The Talk Sidebar Ad is weird in that it appears in two different places
  // This is just the ad for desktop
  if (isDesktop) {
    return (
      <Companion
        path={process.env.NEXT_PUBLIC_AD_PATH_TALKS || ''}
        identifier="talkpage-sidebar-atf"
        className="w-full pb-6 pt-3"
      />
    );
  }
  return <></>;
};

export const TalkAdSideBarBTF = () => {
  const isMobile = useIsBreakpointWidth({ size: 'sm', breakPointType: 'ads' });
  const isTalkTablet = useIsBreakpointWidth({
    size: 'lg',
    breakPointType: 'tui'
  });
  // The Talk Sidebar Ad is weird in that it appears in two different places
  // This is just the ad that appears for Mobile and Tablet
  if (isTalkTablet || isMobile) {
    return (
      <Companion
        path={process.env.NEXT_PUBLIC_AD_PATH_TALKS || ''}
        identifier="talkpage-sidebar-btf"
        className="w-full pt-3"
      />
    );
  }
  return <></>;
};
