import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Microcopy } from 'components/typography';
import { VideoCompanions } from '.';

type AdCompanionProps = {
  path: string;
  videoAd?: {
    token: string;
    custParams?: { [key: string]: string };
  };
  onFillChange?: (filled: boolean) => void;
};

export const AdCompanion = ({ path, videoAd }: AdCompanionProps) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div style={{ width: 300 }}>
      {isVisible && (
        <Microcopy size="s" className="mb-5 pr-5 text-center text-gray-900">
          <FormattedMessage defaultMessage="TED is supported by ads and partners" />
        </Microcopy>
      )}

      <VideoCompanions.MediumRectangle
        path={path}
        identifier="ad-companion"
        onFillChange={setIsVisible}
        videoAd={videoAd}
      />
    </div>
  );
};
