import useVideoPlayerStore from 'components/video-player/store';
import { useState } from 'react';
import { VideoCompanions } from '../ads';
import Trim from './trim';

type BugAdProps = {
  path: string;
  identifier: string;
  className?: string;
};
// TODO: Combine with the ads components
function BugAd({
  path,
  identifier,
  className,
  ...rest
}: BugAdProps): React.ReactNode {
  const [filled, setFilled] = useState(false);
  const videoAd = useVideoPlayerStore(state => state.ad);

  return (
    <Trim className={className} {...rest} filled={filled}>
      <div className="mx-auto w-[120px]">
        <VideoCompanions.Button2
          path={path}
          identifier={identifier}
          videoAd={videoAd}
          onFillChange={setFilled}
        />
      </div>
    </Trim>
  );
}

export default BugAd;
