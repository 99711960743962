import { useIsBreakpointWidth } from 'lib/hooks/useIsBreakpointWidth';
import { useState } from 'react';
import Trim from '../video-ads/trim';
import {
  BillBoard,
  LargeRectangle,
  SmallRectangle,
  SmallRectangleThin
} from './standard-ads';

export const TalkIndexAdBillboard = () => {
  const isMobile = useIsBreakpointWidth({ size: 'sm', breakPointType: 'ads' });
  const isTalkTablet = useIsBreakpointWidth({
    size: 'md',
    breakPointType: 'ads'
  });
  const isDesktop = !isMobile && !isTalkTablet;
  const [isVisible, setIsVisible] = useState(false);
  const paddingAmount = isVisible ? (isDesktop ? 'py-5' : 'py-4') : '';
  const responsiveAd = () => {
    if (isDesktop) {
      return (
        <BillBoard
          path="/ted3/web/talk/talk-index"
          custParams={{ position: 'billboard' }}
          onFillChange={setIsVisible}
        />
      );
    }
    if (isTalkTablet && !isMobile) {
      return (
        <LargeRectangle
          path="/ted3/web/talk/talk-index"
          custParams={{ position: 'billboard' }}
          onFillChange={setIsVisible}
        />
      );
    }
    if (isMobile) {
      return (
        <SmallRectangle
          path="/ted3/web/talk/talk-index"
          custParams={{ position: 'billboard' }}
          onFillChange={setIsVisible}
        />
      );
    }
    return <></>;
  };
  return (
    <section
      data-testid="section__talkindex-ad-abtf"
      className={`flex flex-row items-center justify-center bg-gray-300 ${paddingAmount} ${paddingAmount}`}
    >
      {responsiveAd()}
    </section>
  );
};

export const TalkIndexAdInlineATF = () => {
  const isMobile = useIsBreakpointWidth({ size: 'sm', breakPointType: 'ads' });
  const isTalkTablet = useIsBreakpointWidth({
    size: 'md',
    breakPointType: 'ads'
  });
  const [isVisible, setIsVisible] = useState(false);
  const isDesktop = !isMobile && !isTalkTablet;
  const paddingAmount = isVisible ? (isDesktop ? 'pt-4 pb-8' : 'py-4') : '';
  const responsiveAd = () => {
    if (isDesktop) {
      return (
        <LargeRectangle
          custParams={{ position: 'abovefold' }}
          path="/ted3/web/talk/talk-index/atf"
          onFillChange={setIsVisible}
        />
      );
    }
    if (isTalkTablet && !isMobile) {
      return (
        <LargeRectangle
          custParams={{ position: 'abovefold' }}
          path="/ted3/web/talk/talk-index/atf"
          onFillChange={setIsVisible}
        />
      );
    }
    if (isMobile) {
      return (
        <SmallRectangleThin
          custParams={{ position: 'abovefold' }}
          path="/ted3/web/talk/talk-index/atf"
          onFillChange={setIsVisible}
        />
      );
    }
    return <></>;
  };
  return (
    <section
      data-testid="section__talkindex-ad-header-atf"
      className={`flex flex-row items-center justify-center ${paddingAmount}`}
    >
      <Trim filled={isVisible}>{responsiveAd()}</Trim>
    </section>
  );
};

export const TalkIndexAdInlineBTF = () => {
  const isMobile = useIsBreakpointWidth({ size: 'sm', breakPointType: 'ads' });
  const isTalkTablet = useIsBreakpointWidth({
    size: 'md',
    breakPointType: 'ads'
  });
  const isDesktop = !isMobile && !isTalkTablet;
  const responsiveAd = () => {
    if (isDesktop) {
      return (
        <LargeRectangle
          custParams={{ position: 'abovefold' }}
          path="/ted3/web/talk/talk-index/btf"
        />
      );
    }
    if (isTalkTablet && !isMobile) {
      return (
        <LargeRectangle
          custParams={{ position: 'abovefold' }}
          path="/ted3/web/talk/talk-index/btf"
        />
      );
    }
    if (isMobile) {
      return (
        <SmallRectangleThin
          custParams={{ position: 'abovefold' }}
          path="/ted3/web/talk/talk-index/btf"
        />
      );
    }
    return <></>;
  };
  return (
    <section
      data-testid="section__talkindex-ad-btf"
      className="mb-10 mt-8 flex flex-row items-center justify-center"
    >
      <Trim filled>{responsiveAd()}</Trim>
    </section>
  );
};
