import { LoadingAnimation } from 'components/@tedui';
import { useScrollCheck } from 'lib/hooks/useScrollCheck';
import { useRef, type ReactNode } from 'react';

type LazyLoadAdProps = {
  children: ReactNode;
  showLoader?: boolean;
};

export const LazyLoadAd = ({
  children,
  showLoader = true
}: LazyLoadAdProps) => {
  const displayRef = useRef<HTMLDivElement | null>(null);
  const showRef = useRef<boolean | null>(null);
  const checkVisible = useScrollCheck(displayRef);
  if (!showRef.current) {
    showRef.current = checkVisible;
  }
  // Checks to see if ad currently should be shown.
  const showAd = showRef.current;

  return (
    <div ref={displayRef}>
      {showAd ? (
        children
      ) : showLoader ? (
        <div className="mx-auto h-24 w-24">
          <LoadingAnimation />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
