import cx from 'classnames';

import { Text } from 'components/@tedui';

type TrimProps = {
  filled?: boolean;
  className?: string;
  children: React.ReactNode;
};

const Trim = ({
  filled = false,
  className,
  children,
  ...rest
}: TrimProps): React.ReactNode => {
  return (
    <div className={className} {...(filled && rest)}>
      <div
        className={cx('mx-auto pb-2 text-center', filled ? 'block' : 'hidden')}
      >
        <Text
          variant="caption"
          color={{
            override: '#717171'
          }}
        >
          TED is supported by ads and partners
        </Text>
      </div>

      {children}
    </div>
  );
};

export default Trim;
