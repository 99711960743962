import { useIsBreakpointWidth } from 'lib/hooks/useIsBreakpointWidth';
import { useState } from 'react';
import Trim from '../video-ads/trim';
import {
  BillBoard,
  LargeRectangle,
  Skyscraper,
  SmallRectangle,
  SmallRectangleThin
} from './standard-ads';

export const TopicsAdBilllBoardATF = () => {
  const isMobile = useIsBreakpointWidth({ size: 'sm', breakPointType: 'ads' });
  const isTalkTablet = useIsBreakpointWidth({
    size: 'md',
    breakPointType: 'ads'
  });
  const isDesktop = !isMobile && !isTalkTablet;
  const [isVisible, setIsVisible] = useState(false);
  const paddingAmount = isVisible ? (isDesktop ? 'py-5' : 'py-4') : '';
  const responsiveAd = () => {
    if (isDesktop) {
      return (
        <BillBoard
          path="/ted3/web/topics"
          custParams={{ position: 'billboard' }}
          onFillChange={setIsVisible}
        />
      );
    }
    if (isTalkTablet && !isMobile) {
      return (
        <LargeRectangle
          path="/ted3/web/topics"
          custParams={{ position: 'billboard' }}
          onFillChange={setIsVisible}
        />
      );
    }
    if (isMobile) {
      return (
        <SmallRectangle
          path="/ted3/web/topics"
          custParams={{ position: 'billboard' }}
          onFillChange={setIsVisible}
        />
      );
    }
    return <></>;
  };
  return (
    <section
      data-testid="section__topics-ad-billboard"
      className={`flex flex-row items-center justify-center bg-gray-300 ${paddingAmount} ${paddingAmount}`}
    >
      {responsiveAd()}
    </section>
  );
};

export const TopicsAdInlineATF = () => {
  const isMobile = useIsBreakpointWidth({ size: 'sm', breakPointType: 'ads' });
  const isTalkTablet = useIsBreakpointWidth({
    size: 'md',
    breakPointType: 'ads'
  });
  const isDesktop = !isMobile || !isTalkTablet;
  const containerClass = isMobile
    ? 'mb-12 mt-12 flex flex-row items-center justify-center'
    : 'mb-10 ml-20 mt-20 flex flex-row items-center justify-center';
  const responsiveAd = () => {
    if (isDesktop) {
      return (
        <Skyscraper
          custParams={{ position: 'abovefold' }}
          path="/ted3/web/topics"
        />
      );
    }
    if (isTalkTablet && !isMobile) {
      return (
        <Skyscraper
          custParams={{ position: 'abovefold' }}
          path="/ted3/web/topics"
        />
      );
    }
    if (isMobile) {
      return (
        <SmallRectangleThin
          custParams={{ position: 'abovefold' }}
          path="/ted3/web/topics"
        />
      );
    }
    return <></>;
  };
  return (
    <section
      data-testid="section__topics-ad-header-atf"
      className={containerClass}
    >
      <Trim filled>{responsiveAd()}</Trim>
    </section>
  );
};

export const TopicResultsAdInlineATFDesktop = () => {
  const isMobile = useIsBreakpointWidth({
    size: 'sm',
    breakPointType: 'topicResultsAds'
  });
  const isTalkTablet = useIsBreakpointWidth({
    size: 'md',
    breakPointType: 'topicResultsAds'
  });
  const isDesktop = !isMobile && !isTalkTablet;
  const containerClass = 'mt-20 flex flex-row items-center justify-center';

  const responsiveAd = () => {
    if (isDesktop) {
      return (
        <Trim filled>
          <Skyscraper
            custParams={{ position: 'abovefold' }}
            path="/ted3/web/topics"
          />
        </Trim>
      );
    }
    return null;
  };

  return (
    <section
      data-testid="section__topicsresults-ad-header-atf"
      className={containerClass}
    >
      {responsiveAd()}
    </section>
  );
};

export const TopicResultsAdInlineATFMobile = () => {
  const [isVisible, setIsVisible] = useState(false);
  const isMobile = useIsBreakpointWidth({ size: 'sm', breakPointType: 'ads' });
  const isTalkTablet = useIsBreakpointWidth({
    size: 'md',
    breakPointType: 'ads'
  });
  const containerClass =
    'mb-12 mt-12 flex flex-row items-center justify-center';
  const responsiveAd = () => {
    if (isTalkTablet && !isMobile) {
      return (
        <Trim filled={isVisible}>
          <SmallRectangleThin
            path="/ted3/web/topics"
            custParams={{ position: 'abovefold' }}
            onFillChange={setIsVisible}
          />
        </Trim>
      );
    }
    if (isMobile) {
      return (
        <Trim filled={isVisible}>
          <SmallRectangleThin
            path="/ted3/web/topics"
            custParams={{ position: 'abovefold' }}
            onFillChange={setIsVisible}
          />
        </Trim>
      );
    }
    return <></>;
  };
  return (
    <section
      data-testid="section__topicsresults-ad-header-atf"
      className={containerClass}
    >
      {responsiveAd()}
    </section>
  );
};

export const TopicResultsAdsInlineBTF = () => {
  const isMobile = useIsBreakpointWidth({ size: 'sm', breakPointType: 'ads' });
  const isTalkTablet = useIsBreakpointWidth({
    size: 'md',
    breakPointType: 'ads'
  });
  const isDesktop = !isMobile && !isTalkTablet;
  const [isVisible, setIsVisible] = useState(false);
  const responsiveAd = () => {
    if (isDesktop) {
      return (
        <Trim
          className="mb-8 mt-8 flex flex-col items-center"
          filled={isVisible}
        >
          <LargeRectangle
            path="/ted3/web/topics/btf"
            onFillChange={setIsVisible}
          />
        </Trim>
      );
    }
    if (isTalkTablet && !isMobile) {
      return (
        <Trim
          className="mb-8 mt-8 flex flex-col items-center"
          filled={isVisible}
        >
          <LargeRectangle
            path="/ted3/web/topics/btf"
            onFillChange={setIsVisible}
          />
        </Trim>
      );
    }
    if (isMobile) {
      return (
        <Trim
          className="mb-8 mt-8 flex flex-col items-center"
          filled={isVisible}
        >
          <SmallRectangleThin
            path="/ted3/web/topics/btf"
            onFillChange={setIsVisible}
          />
        </Trim>
      );
    }
    return <></>;
  };
  return responsiveAd();
};

export const TopicsAdInlineBTF = () => {
  const isMobile = useIsBreakpointWidth({ size: 'sm', breakPointType: 'ads' });
  const isTalkTablet = useIsBreakpointWidth({
    size: 'md',
    breakPointType: 'ads'
  });
  const isDesktop = !isMobile || !isTalkTablet;
  const [isVisible, setIsVisible] = useState(false);
  const responsiveAd = () => {
    if (isDesktop) {
      return (
        <Trim className="mt-10" filled={isVisible}>
          <LargeRectangle
            path="/ted3/web/topics/btf"
            onFillChange={setIsVisible}
          />
        </Trim>
      );
    }
    if (isTalkTablet && !isMobile) {
      return (
        <Trim className="mt-10" filled={isVisible}>
          <LargeRectangle
            path="/ted3/web/topics/btf"
            onFillChange={setIsVisible}
          />
        </Trim>
      );
    }
    if (isMobile) {
      return (
        <Trim className="mt-10" filled={isVisible}>
          <SmallRectangleThin
            path="/ted3/web/topics/btf"
            onFillChange={setIsVisible}
          />
        </Trim>
      );
    }
    return <></>;
  };
  return (
    <div className="flex flex-row items-center justify-center">
      {responsiveAd()}
    </div>
  );
};
