import * as VideoCompanions from './video-companions';
import * as HomePageAds from './homepage-ads';
import * as TalkPageAds from './talkpage-ads';
import * as TalkIndexAds from './talkindex-ads';
import * as TopicPageAds from './topicspage-ads';
import * as PlaylistAds from './playlist-ads';
import * as GameAds from './game-ads';

export { buildAdPath } from 'lib/ads/index';

export {
  MediumRectangle,
  LargeRectangle,
  SmallRectangle,
  SmallRectangleThin,
  ResponsiveRectangle as ResponsiveAd,
  BillBoard
} from './standard-ads';

export { LazyLoadAd } from './lazyload-ad';

export { AdCompanion } from './ad-companion';

export {
  VideoCompanions,
  HomePageAds,
  TalkPageAds,
  TalkIndexAds,
  TopicPageAds,
  PlaylistAds,
  GameAds
};

export {
  VIDEO_AD_TAG_NO_COMPANIONS,
  VIDEO_AD_TAG_WITH_COMPANIONS
} from './constants';
