import cx from 'classnames';
import { useMemo, useState } from 'react';

import useVideoPlayerStore from 'components/video-player/store';

import { VideoCompanions } from '../ads';
import Trim from './trim';

type CompanionAdProps = {
  path: string;
  identifier: string;
  type?: 'LargeRectangle' | 'MediumRectangle' | 'SmallRectangleThin';
  className?: string;
  showTrim?: boolean;
};
// TODO: Combine with the ads components
function CompanionAd({
  path,
  identifier,
  type = 'MediumRectangle',
  className,
  showTrim = true,
  ...rest
}: CompanionAdProps): React.ReactNode {
  const [filled, setFilled] = useState(false);
  const videoAd = useVideoPlayerStore(state => state.ad);

  const VideoCompanionType = useMemo(() => {
    switch (type) {
      case 'LargeRectangle':
        return VideoCompanions.LargeRectangle;
      case 'SmallRectangleThin':
        return VideoCompanions.SmallRectangleThin;
      case 'MediumRectangle':
      default:
        return VideoCompanions.MediumRectangle;
    }
  }, [type]);

  return showTrim ? (
    <Trim className={className} {...rest} filled={filled}>
      <div
        className={cx('mx-auto', {
          'w-[300px]': type === 'MediumRectangle',
          'w-[728px]': type === 'LargeRectangle',
          'w-[320px]': type === 'SmallRectangleThin'
        })}
      >
        <VideoCompanionType
          identifier={identifier}
          path={path}
          videoAd={videoAd}
          onFillChange={setFilled}
        />
      </div>
    </Trim>
  ) : (
    <div
      className={cx('mx-auto', filled ? className : 'mb-10', 'flex', {
        'w-[300px]': type === 'MediumRectangle',
        'w-[728px]': type === 'LargeRectangle',
        'w-[320px]': type === 'SmallRectangleThin'
      })}
    >
      <VideoCompanionType
        identifier={identifier}
        path={path}
        videoAd={videoAd}
        onFillChange={setFilled}
      />
    </div>
  );
}

export default CompanionAd;
