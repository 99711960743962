import type { RefObject } from 'react';
import { useEffect, useRef, useState } from 'react';

export const useScrollCheck = (ref: RefObject<HTMLElement | null>) => {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const [isOnScreen, setIsOnScreen] = useState(false);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) =>
      setIsOnScreen(entry.isIntersecting)
    );
  }, []);

  useEffect(() => {
    if (ref?.current && observerRef?.current) {
      observerRef.current.observe(ref.current);
    }

    return () => {
      observerRef?.current?.disconnect?.();
    };
  }, [ref]);

  return isOnScreen;
};
